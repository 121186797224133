import { useState } from 'react';
import { Form, Input, Divider, Tooltip } from 'antd';
import { ArrowLeftOutlined, PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { useData, useMethods, access, translit,print } from 'utils';
import { Link, ProductCard } from 'components';
import { pizzaSizes } from 'const';
import { refreshOrderAction } from 'actions';

export const SearchProducts = (props) => {
	const { setPending } = props;
	const { orderId, order = {}, products = [], nomenclatures = [] } = useData();
	const { __, update } = useMethods();
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState({});
	const [filteredNomenclatures, setFilteredNomenclatures] = useState([]);
	const [selectedNomenclatures, setSelectedNomenclatures] = useState({});
	const orderNomenclatures = order.nomenclatures || [];
	const isStatusEnd = order.status_id ? ![1, 2, 3, 4, 5, 12].includes(order.status_id) : true;
	const isAccessOrdersChange = orderId ? access('orders-items-change') : true;
	const [form] = Form.useForm();

	const productsSearchHandler = (event) => {
		const name = event.target.value.trim().toLowerCase();
		if (name) {
			setFilteredProducts(
				name.length >= 2
					? products.filter(
							(item) =>
								!!Object.values(item.name).filter(
									(text) => text.toLowerCase().includes(name) || text.toLowerCase().includes(translit(name))
								).length
					  )
					: []
			);
		} else {
			setFilteredProducts([]);
		}
	};

	const nomenclaturesSearchHandler = (event) => {
		const name = event.target.value.trim().toLowerCase();
		if (name) {
			setFilteredNomenclatures(
				name.length >= 2 ? nomenclatures.filter((item) => item.name.toLowerCase().includes(name) && item.price > 0) : []
			);
		} else {
			setFilteredNomenclatures([]);
		}
	};

	const countProductChangeHandler = (product, value) => {
		setSelectedProducts({
			...selectedProducts,
			[product.id]: {
				...selectedProducts[product.id],
				quantity: value
			}
		});
	};

	const sizeProductChangeHandler = (product, event) => {
		const value = event.target.value;
		setSelectedProducts({
			...selectedProducts,
			[product.id]: {
				...selectedProducts[product.id],
				pizza_size: value
			}
		});
	};

	const addProductHandler = async (item) => {
		setPending(true);
		const products = (order.products || [])
			.concat([
				{
					...item,
					quantity: selectedProducts[item.id] && selectedProducts[item.id].quantity ? selectedProducts[item.id].quantity : 1,
					pizza_size: item.is_pizza
						? selectedProducts[item.id] && selectedProducts[item.id].pizza_size
							? selectedProducts[item.id].pizza_size
							: pizzaSizes[0].value
						: 0
				}
			])
			.map((item, index) => ({
				...item,
				_uid: `product-${index}-${item.id}`
			}));
		setFilteredProducts([]);
		form.resetFields();
		const orderResponse = await refreshOrderAction({
			...order,
			products
		});
		setPending(false);
		update({
			order: orderResponse
		});
	};

	const countNomenclatureChangeHandler = (nom, value) => {
		setSelectedNomenclatures({
			...selectedNomenclatures,
			[nom.id]: {
				...selectedNomenclatures[nom.id],
				quantity: value
			}
		});
	};

	const addNomenclatureHandler = async (item) => {
		setPending(true);
		const nomenclatures = orderNomenclatures
			.concat([
				{
					...item,
					quantity: selectedNomenclatures[item.id] && selectedNomenclatures[item.id].quantity ? selectedNomenclatures[item.id].quantity : 1,
					isNomenclature: true
				}
			])
			.map((item, index) => ({
				...item,
				_uid: `nomenclature-${index}-${item.id}`
			}));
		setFilteredNomenclatures([]);
		form.resetFields();
		const orderResponse = await refreshOrderAction({
			...order,
			nomenclatures
		});
		setPending(false);
		update({
			order: orderResponse
		});
	};
	const printTable = () => {
		print('#active-order-products  .ant-table-body', {}, `@page {margin: 1.5cm 1cm 1.4cm 1cm; size: landscape;}`);
	};
	return (
		<Form className="form form--small" form={form} noValidate={true}>
			<div className="product-search">
				<div className="product-search__container">
					<div className="product-search__link filter__buttons">
						<Link href="/orders" className="button button--main">
							<ArrowLeftOutlined />
							<span>{__('Назад')}</span>
						</Link>
						<div className="filter__button">
							<Tooltip title={__('Распечатать')} placement="bottomRight">
								<button className="button button--main-border button--icon-small" onClick={printTable}>
									<PrinterOutlined />
								</button>
							</Tooltip>
						</div>
					</div>
					{!isStatusEnd && isAccessOrdersChange ? (
						<>
							<div className="product-search__input">
								<Form.Item className="form__item" name="product_name">
									<Input
										placeholder={__('Поиск продуктов')}
										prefix={<SearchOutlined />}
										allowClear={true}
										onChange={productsSearchHandler}
									/>
								</Form.Item>
							</div>
							<div className="product-search__input">
								<Form.Item className="form__item" name="nomenclature_name">
									<Input
										placeholder={__('Поиск добавок')}
										prefix={<SearchOutlined />}
										allowClear={true}
										onChange={nomenclaturesSearchHandler}
									/>
								</Form.Item>
							</div>
						</>
					) : null}
				</div>
				{filteredProducts.length || filteredNomenclatures.length ? (
					<div className="product-search__list">
						{filteredProducts.length ? (
							<div className="product-search__row">
								{filteredProducts.map((product) => {
									const priceDiameter = product.is_pizza
										? selectedProducts[product.id] && selectedProducts[product.id].pizza_size
											? product['price_' + selectedProducts[product.id].pizza_size]
											: product['price_' + pizzaSizes[0].value]
										: null;
									const price = priceDiameter !== null ? priceDiameter : product.price;

									const pizzaSize = product.is_pizza
										? selectedProducts[product.id] && selectedProducts[product.id].pizza_size
											? selectedProducts[product.id].pizza_size
											: pizzaSizes[0].value
										: 0;
									const quantity =
										selectedProducts[product.id] && selectedProducts[product.id].quantity
											? selectedProducts[product.id].quantity
											: 1;

									return (
										<div key={product.id} className="product-search__col">
											<ProductCard
												product={product}
												price={price}
												pizzaSize={pizzaSize}
												quantity={quantity}
												onChangeCount={countProductChangeHandler}
												onChangeSize={sizeProductChangeHandler}
												onAdd={addProductHandler}
											/>
										</div>
									);
								})}
							</div>
						) : null}
						{filteredProducts.length && filteredNomenclatures.length ? <Divider /> : null}
						{filteredNomenclatures.length ? (
							<div className="product-search__row">
								{filteredNomenclatures.map((nom) => {
									const quantity =
										selectedNomenclatures[nom.id] && selectedNomenclatures[nom.id].quantity
											? selectedNomenclatures[nom.id].quantity
											: 1;
									return (
										<div key={nom.id} className="product-search__col">
											<ProductCard
												isNomenclature={true}
												product={nom}
												quantity={quantity}
												onChangeCount={countNomenclatureChangeHandler}
												onAdd={addNomenclatureHandler}
											/>
										</div>
									);
								})}
							</div>
						) : null}
					</div>
				) : null}
			</div>
		</Form>
	);
};
