import { Table, Row, Col, Radio, InputNumber, Tooltip, Popconfirm } from 'antd';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { useData, useMethods, access, isFractionalNomenclature, createNomenclatureName, formatDigits } from 'utils';
import { CountInput, ChangeRelatedProducts, ChangeProductNote } from 'components';
import { pizzaSizes } from 'const';
import { refreshOrderAction } from 'actions';
import { ReactComponent as RowIcon } from 'assets/svg/row.svg';
import { ReactComponent as ColIcon } from 'assets/svg/col.svg';

export const OrderComposition = (props) => {
	const { setPending } = props;
	const { orderId, order = {}, locale, nomenclatures: nomenclaturesList = [] } = useData();
	const { products = [], nomenclatures = [], promo = {} } = order;
	const { __, update } = useMethods();
	const isAccessOrdersChange = orderId ? access('orders-items-change') : true;
	const isStatusEnd = order.status_id ? ![1, 2, 3, 4, 5, 12].includes(order.status_id) : true;
	const data = promo._uid ? [...products, ...nomenclatures, promo] : [...products, ...nomenclatures];

	const allData = [];
	data.forEach((item) => {
		allData.push(item);
		if (item.products && item.products.length) {
			item.products.forEach((product) => {
				allData.push({
					_uid: `${item._uid}-products-${product.id}`,
					_productUid: item._uid,
					isProduct: true,
					...product
				});
			});
		}
		if (item.ingredients && item.ingredients.length) {
			item.ingredients.forEach((ingredient) => {
				allData.push({
					_uid: `${item._uid}-ingredients-${ingredient.id}`,
					_productUid: item._uid,
					isIngredient: true,
					...ingredient
				});
			});
		}
	});

	const sizeProductChangeHandler = async (product, event) => {
		setPending(true);
		const value = event.target.value;
		const mappedProducts = products.map((item) => {
			if (item._uid === product._uid) {
				const dataProduct = products.find((item) => item.id === product.id);
				return {
					...dataProduct,
					...item,
					pizza_size: value
				};
			}
			return item;
		});
		const orderResponse = await refreshOrderAction({
			...order,
			products: mappedProducts
		});
		setPending(false);
		update({
			order: orderResponse
		});
	};

	const countChangeHandler = async (record, value) => {
		setPending(true);
		const orderData = {
			...order
		};
		if (record.isNomenclature) {
			const val = value || 1;
			orderData.nomenclatures = nomenclatures.map((item) => {
				if (item._uid === record._uid) {
					return {
						...item,
						quantity: val
					};
				}
				return item;
			});
		} else {
			orderData.products = products.map((item) => {
				if (item._uid === record._uid) {
					return {
						...item,
						quantity: value
					};
				}
				return item;
			});
		}
		const orderResponse = await refreshOrderAction(orderData);
		setPending(false);
		update({
			order: orderResponse
		});
	};

	const deleteHandler = async (uid, isNomenclature) => {
		setPending(true);

		let filteredProducts = products;
		let filteredNomenclatures = nomenclatures;
		if (isNomenclature) {
			filteredNomenclatures = filteredNomenclatures.filter((item) => item._uid !== uid);
		} else {
			filteredProducts = filteredProducts.filter((item) => item._uid !== uid);
		}

		const orderResponse = await refreshOrderAction({
			...order,
			products: filteredProducts,
			nomenclatures: filteredNomenclatures
		});
		setPending(false);

		update({
			order: orderResponse
		});
	};

	const deleteRelatedProduct = async (uid, relatedId) => {
		setPending(true);
		const mappedProducts = products.map((item) => {
			if (uid === item._uid) {
				return {
					...item,
					products: (item.products || []).filter((product) => product.id !== relatedId)
				};
			} else {
				return item;
			}
		});
		const orderResponse = await refreshOrderAction({
			...order,
			products: mappedProducts
		});
		setPending(false);
		update({
			order: orderResponse
		});
	};

	const deleteRelatedIngredient = async (uid, ingredientId) => {
		setPending(true);
		const mappedProducts = products.map((item) => {
			if (uid === item._uid) {
				return {
					...item,
					ingredients: (item.ingredients || []).filter((ingredient) => ingredient.id !== ingredientId)
				};
			} else {
				return item;
			}
		});
		const orderResponse = await refreshOrderAction({
			...order,
			products: mappedProducts
		});
		setPending(false);
		update({
			order: orderResponse
		});
	};

	return (
		<div className="order-composition" id="order-composition">
			<Row gutter={16} justify="space-between" style={{ paddingBottom: '10px' }}>
				<Col>
					<h2>
						{orderId
							? `${__('Редактирование текущего заказа')}${order.day_id ? ` №${order.day_id}` : ''}`
							: __('Формирование нового заказа')}
					</h2>
				</Col>
			</Row>
			<Table
				id="active-order-products"
				className="table table--composition"
				dataSource={allData}
				scroll={{
					x: 1000,
					y: 700
				}}
				rowKey="_uid"
				pagination={false}
				rowClassName={(record) => {
					let classNames = [];
					if (record.is_promo) {
						classNames.push('is-promocode');
					}
					if (record.isNomenclature) {
						classNames.push('is-nomenclature');
					}
					if (record.isProduct || record.isIngredient) {
						classNames.push('is-inner');
					}
					return classNames.join(' ');
				}}
				columns={[
					{
						title: __('Действие'),
						width: 80,
						render: (item, record) => {
							const isAccessRelatedProducts =
								!isStatusEnd &&
								((record.ingredientable && record.relatedIngredients && record.relatedIngredients.length) ||
									(record.relatedProducts && record.relatedProducts.length && ![95, 143].includes(record.category_id)));

							if (record.is_promo || record.isNomenclature) {
								return null;
							} else if (record.isProduct || record.isIngredient) {
								return (
									<div className="order-composition__col-icon">
										<ColIcon />
									</div>
								);
							} else {
								return (
									<Row style={{ flexWrap: 'nowrap' }}>
										<Col>
											<ChangeProductNote product={record}>
												<Tooltip placement="topLeft" title={__('Добавить примечание')}>
													<button className="button button--trans-main button--icon-xs">
														<FormOutlined />
													</button>
												</Tooltip>
											</ChangeProductNote>
										</Col>
										{isAccessRelatedProducts ? (
											<Col>
												<ChangeRelatedProducts product={record}>
													<Tooltip placement="topLeft" title={__('Редактировать состав')}>
														<button
															className="button button--trans-main button--icon-xs"
															disabled={record.is_promo || !isAccessOrdersChange}
														>
															<RowIcon />
														</button>
													</Tooltip>
												</ChangeRelatedProducts>
											</Col>
										) : null}
									</Row>
								);
							}
						}
					},
					{
						title: __('Наименование'),
						dataIndex: 'name',
						width: 200,
						render: (name, record) => {
							if (record.isProduct || record.isIngredient) {
								return <strong>{name}</strong>;
							} else {
								const nomInfo = record.isNomenclature ? nomenclaturesList.find(({ id }) => record.id === id) : null;
								const text = name[locale] || name;
								const nameTitle = nomInfo ? createNomenclatureName(nomInfo.name, nomInfo.unit_title, nomInfo.unit_weight, __) : text;
								return (
									<div>
										<strong>{nameTitle}</strong>
										{record.is_pizza ? (
											<div>
												<Radio.Group
													disabled={!isAccessOrdersChange}
													defaultValue={record.pizza_size || pizzaSizes[0].value}
													onChange={(event) => sizeProductChangeHandler(record, event)}
												>
													{pizzaSizes.map((item) => (
														<Radio value={item.value} key={item.value} disabled={!!record.is_promo}>
															{item.text}
														</Radio>
													))}
												</Radio.Group>
											</div>
										) : null}
									</div>
								);
							}
						}
					},
					{
						title: __('Цена за ед'),
						dataIndex: 'formatted_price',
						width: 100,
						render: (_, record) => {
							if (record.isProduct || record.isIngredient) {
								return `${formatDigits({ number: record.price || 0, toFixed: 2 })} ${__('грн')}`;
							} else {
								return `${formatDigits({ number: record?.formatted?.price || 0, toFixed: 2 })} ${__('грн')}`;
							}
						}
					},
					{
						title: __('Кол-во'),
						dataIndex: 'quantity',
						align: 'center',
						width: 150,
						render: (quantity, record) => {
							if (record.isProduct || record.isIngredient) {
								return quantity;
							} else {
								const unitTitle = record.isNomenclature
									? record.unit_title || nomenclatures.find(({ id }) => record.id === id)?.unit_title
									: null;
								const isFractionalItem = isFractionalNomenclature(unitTitle, __);
								return record.is_promo || !isAccessOrdersChange ? (
									quantity
								) : isFractionalItem ? (
									<>
										<InputNumber
											step={0.1}
											min={0.1}
											max={9999}
											parser={(val) => {
												return val?.replace(',', '.');
											}}
											precision={3}
											value={quantity}
											disabled={record.is_promo || !isAccessOrdersChange}
											onChange={(value) => countChangeHandler(record, value)}
										/>
										{` ${unitTitle}`}
									</>
								) : (
									<CountInput
										min={1}
										max={9999}
										value={quantity}
										disabled={record.is_promo || !isAccessOrdersChange}
										onChange={(value) => countChangeHandler(record, value)}
									/>
								);
							}
						}
					},
					{
						title: __('Сумма'),
						dataIndex: 'formatted_price_full',
						width: 100,
						render: (_, record) => `${formatDigits({ number: record?.formatted?.price_full || 0, toFixed: 2 })} ${__('грн')}`
					},
					{
						title: __('Сумма скидки'),
						dataIndex: 'formatted_sale_full',
						width: 120,
						render: (_, record) => {
							if (record.isProduct || record.isIngredient) {
								return '';
							} else {
								return record.is_promo
									? __('Бесплатно')
									: `${formatDigits({ number: record?.formatted?.sale_full || 0, toFixed: 2 })} ${__('грн')}`;
							}
						}
					},
					{
						title: __('Сумма со скидкой'),
						dataIndex: 'formatted_sale_price_full',
						width: 110,
						render: (text, record) => {
							if (record.isProduct || record.isIngredient) {
								return '';
							} else {
								return `${formatDigits({ number: record?.formatted?.sale_price_full || 0, toFixed: 2 })} ${__('грн')}`;
							}
						}
					},
					{
						title: __('Удалить'),
						width: 100,
						align: 'right',
						render: (item, record) => {
							if (isStatusEnd || !!record.is_promo || !isAccessOrdersChange) {
								return '';
							} else if (record.isProduct) {
								return (
									<Popconfirm
										placement="bottomRight"
										title={__('Удалить добавку?')}
										onConfirm={() => deleteRelatedProduct(record._productUid, record.id)}
										okText={__('Ок')}
										cancelText={__('Отменить')}
									>
										<Tooltip placement="topRight" title={__('Удалить добавку')}>
											<button type="button" className="button button--trans-error button--icon-xs">
												<DeleteOutlined />
											</button>
										</Tooltip>
									</Popconfirm>
								);
							} else if (record.isIngredient) {
								return (
									<Popconfirm
										placement="bottomRight"
										title={__('Удалить ингредиент?')}
										onConfirm={() => deleteRelatedIngredient(record._productUid, record.id)}
										okText={__('Ок')}
										cancelText={__('Отменить')}
									>
										<Tooltip placement="topRight" title={__('Удалить ингредиент')}>
											<button type="button" className="button button--trans-error button--icon-xs">
												<DeleteOutlined />
											</button>
										</Tooltip>
									</Popconfirm>
								);
							} else {
								return (
									<Popconfirm
										title={record.isNomenclature ? __('Удалить добавку?') : __('Удалить продукт?')}
										okText={__('Да')}
										cancelText={__('Отмена')}
										placement="topRight"
										onConfirm={() => deleteHandler(record._uid, record.isNomenclature)}
									>
										<Tooltip
											placement="bottomRight"
											title={record.isNomenclature ? __('Удалить добавку') : __('Удалить продукт')}
										>
											<button type="button" className="button button--trans-error button--icon-xs">
												<DeleteOutlined />
											</button>
										</Tooltip>
									</Popconfirm>
								);
							}
						}
					}
				]}
				footer={() => (
					<div className="table__footer">
						<Row justify={'end'}>
							<Col>
								<span className="order-composition__text">{__('Стоимость доставки')}: </span>
								<span className="order-composition__sum">
									{formatDigits({ number: order.delivery_sum || 0, toFixed: 2 })} {__('грн')}
								</span>
							</Col>
							<Col>
								<span className="order-composition__text">{__('Скидка')}: </span>
								<span className="order-composition__sum">
									{formatDigits({ number: order.discount_sum || 0, toFixed: 2 })} {__('грн')}
								</span>
							</Col>
							<Col>
								<span className="order-composition__text">{__('Сумма заказа')}: </span>
								<span className="order-composition__sum">
									{formatDigits({ number: order.price_full || 0, toFixed: 2 })} {__('грн')}
								</span>
							</Col>
						</Row>
						<Row justify={'end'}>
							<Col>
								<span className="order-composition__total">{__('Итого')}: </span>
								<span className="order-composition__total">
									{formatDigits({ number: order.price || 0, toFixed: 2 })} {__('грн')}
								</span>
							</Col>
						</Row>
					</div>
				)}
			/>
		</div>
	);
};
